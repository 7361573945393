<template>
  <aside>
    <div class="aside aside-toggle">
      <img src="/media/layout/aside/open.svg" alt="" />
    </div>
    <Menu />
  </aside>
</template>

<script>
import Menu from "./Menu";
export default {
  name: "Aside",
  components: { Menu }
};
</script>
