<template>
  <div class="aside aside-menu">
    <ul>
      <li>
        <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          to="/calculator/auto"
        >
          <a :href="href" @click="navigate">
            <img src="/media/layout/aside/menu-car.svg" alt="" />
            <span>AUTO</span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          to="/calculator/property"
        >
          <a :href="href" @click="navigate">
            <img src="/media/layout/aside/menu-property.svg" alt="" />
            <span>MAJĄTEK</span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          to="/calculator/travel"
        >
          <a :href="href" @click="navigate">
            <img src="/media/layout/aside/menu-travel.svg" alt="" />
            <span>PODRÓŻE</span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          to="/calculator/life"
        >
          <a :href="href" @click="navigate">
            <img src="/media/layout/aside/menu-life.svg" alt="" />
            <span>ŻYCIE</span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          to="/settings"
        >
          <a :href="href" @click="navigate">
            <img src="/media/layout/aside/menu-settings.svg" alt="" />
            <span>ŻYCIE</span>
          </a>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Menu"
};
</script>
