<template>
  <div v-if="isAuthenticated" class="page-wrapper">
    <Aside />
    <Header />
    <div class="content">
      <transition name="fade-in-up">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Aside from "./aside/Aside";
import Header from "./header/Header";

export default {
  name: "Layout",
  components: {
    Aside,
    Header
  },
  computed: {
    ...mapGetters(["isAuthenticated", "breadcrumbs", "pageTitle"])
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
  }
};
</script>
