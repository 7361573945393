var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aside aside-menu"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/calculator/auto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{attrs:{"src":"/media/layout/aside/menu-car.svg","alt":""}}),_c('span',[_vm._v("AUTO")])])]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":"/calculator/property"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{attrs:{"src":"/media/layout/aside/menu-property.svg","alt":""}}),_c('span',[_vm._v("MAJĄTEK")])])]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":"/calculator/travel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{attrs:{"src":"/media/layout/aside/menu-travel.svg","alt":""}}),_c('span',[_vm._v("PODRÓŻE")])])]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":"/calculator/life"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{attrs:{"src":"/media/layout/aside/menu-life.svg","alt":""}}),_c('span',[_vm._v("ŻYCIE")])])]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":"/settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{attrs:{"src":"/media/layout/aside/menu-settings.svg","alt":""}}),_c('span',[_vm._v("ŻYCIE")])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }